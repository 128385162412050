/* body fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Playfair+Display:400,700');

@tailwind base;

@layer base {
    

    body{
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        color: #333;

    }
    @media (max-width: 767px) {
        body {
            font-size: 15px;
        }
    }
    p {
        color: #666;
        line-height: 28px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Playfair Display', serif;
        color: #282e3f;
    }

}

@tailwind components;
@tailwind utilities;


.section-wrap{
    @apply py-[120px] md:py-[90px] sm:py-[80px] relative  z-10
}
.wraper{
    @apply mx-auto max-w-[1140px] xl:px-[12px]  lg:px-[12px] md:px-[20px] sm:px-[10px]
}
        
.theme-btn-2{
    @apply text-base text-[#59C378] inline-block px-5 py-2 border border-[#496451] bg-transparent transition-all hover:bg-[#59C378] hover:border-[transparent] hover:text-white
}

.clip-your-needful-style {
    -webkit-clip-path: polygon(0 0, 100% 0, 55% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 55% 100%, 0% 100%);
}

